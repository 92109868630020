import React, { forwardRef } from 'react'

import ButtonWithIcons, { type ButtonWithIconProps } from './ButtonWithIcons/ButtonWithIcons'
import SimpleButton from './SimpleButton/SimpleButton'


export type ButtonProps = ButtonWithIconProps

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>((props, ref) => {
  const { leftIcon, rightIcon, ...rest } = props

  if (leftIcon || rightIcon) {
    return <ButtonWithIcons ref={ref} {...props} />
  }

  return <SimpleButton ref={ref} {...rest} />
})


export default Button
