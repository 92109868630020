import React from 'react'
import { Combobox } from '@headlessui/react'
import cx from 'classnames'


type OptionRenderProps = {
  active?: boolean
  selected?: boolean
  disabled?: boolean
}

type OptionProps = {
  value: any
  children: React.ReactElement<OptionRenderProps>
}

const Option: React.FunctionComponent<OptionProps> = ({ value, children }) => {
  return (
    <Combobox.Option
      value={value}
      className={({ active }) => cx('px-16 py-4', active && 'bg-light-beige')}
    >
      {
        (props) => React.cloneElement(React.Children.only(children), props)
      }
    </Combobox.Option>
  )
}

export default Option
