import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Field, useFieldState } from 'formular'
import { cx, twcx } from 'helpers/twcx'
import { useIntl } from 'intl'
import { required } from 'helpers/validators'
import { getGlobalHtmlAttrs, type GlobalHTMLAttrs } from 'helpers/getters'

import InputError from '../InputError/InputError'
import type { AutosizeTextareaProps } from './AutosizeTextarea'
import AutosizeTextarea from './AutosizeTextarea'

import s from './Textarea.module.css'


export type TextareaProps = GlobalHTMLAttrs<'HTMLTextAreaElement'> & Omit<AutosizeTextareaProps, 'placeholder'> & {
  inputClassName?: string
  field: Field<string>
  placeholder: Intl.Message | string
  plain?: boolean // plain view without padding and border
  focusOnMount?: boolean
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
  onChange?: (value: any) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>
  isResizable?: boolean
  withBorder?: boolean
}

const Textarea: React.FunctionComponent<TextareaProps> = (props) => {
  const {
    className, inputClassName, field, placeholder, rows = 4, plain, focusOnMount,
    onFocus, onBlur, onChange, onResize, onKeyDown, isResizable = true, withBorder = true,
    ...rest
  } = props

  const intl = useIntl()
  const ref = useRef<HTMLTextAreaElement>()

  const { value, error } = useFieldState<string>(field)

  useEffect(() => {
    if (focusOnMount && ref.current) {
      ref.current.focus()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleBlur = useCallback(async (event) => {
    // @ts-ignore
    await field.validate()

    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }, [ field, onBlur ])

  const handleChange = useCallback((event) => {
    const value = event.target.value

    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ field, onChange ])

  const isErrored = Boolean(error)

  const textareaClassName = twcx(s.textarea, 'w-full bg-white text-black', {
    'rounded': !plain,
    'border-solid-gray-30': !plain && withBorder,
    'border-solid-white': !withBorder,
    [s.standard]: !plain,
    [s.errored]: isErrored,
  }, inputClassName)

  const placeholderString = useMemo(() => intl.formatMessage(placeholder), [ placeholder ]) // eslint-disable-line react-hooks/exhaustive-deps

  const isRequired = field.validators.includes(required)

  const htmlAttrs = getGlobalHtmlAttrs<GlobalHTMLAttrs<'HTMLTextAreaElement'>>(rest)

  const textareaAttrs = {
    ...htmlAttrs,
    ref: ref,
    className: textareaClassName,
    value: value,
    placeholder: placeholderString,
    rows: rows,
    onFocus: onFocus,
    onBlur: handleBlur,
    onResize: onResize,
    onKeyDown: onKeyDown,
    onChange: handleChange,
    'aria-required': isRequired,
    'aria-multiline': true,
  }

  return (
    <div className={className}>
      {
        isResizable ? (
          <AutosizeTextarea
            {...textareaAttrs}
          />

        ) : (
          <textarea {...textareaAttrs} />
        )
      }
      {
        isErrored && (
          <InputError className="mt-4" message={error} />
        )
      }
    </div>
  )
}


export default Textarea
