import React from 'react'
import { Switch as SwitchWrapper } from '@headlessui/react'
import { Field, useFieldState } from 'formular'
import cx from 'classnames'
import { getGlobalHtmlAttrs } from 'helpers/getters'
import type { GlobalHTMLAttrs } from 'helpers/getters'

import s from './Switch.module.css'


export const sizes = [ 30 ] as const

export type Size = typeof sizes[number]

export type SwitchProps = {
  field: Field<boolean>
  size: Size
  style?: 'default' | 'gold'
  onChange?: (value: boolean) => void
}

type SubComponents = {
  Group?: typeof SwitchWrapper['Group']
  Label?: typeof SwitchWrapper['Label']
}

type SwitchComponent = React.FunctionComponent<SwitchProps & GlobalHTMLAttrs> & SubComponents

const Component: SwitchComponent = (props) => {
  const { field, size, style = 'default', onChange, ...rest } = props

  const { value } = useFieldState<boolean>(field)
  const globalHtmlAttrs = getGlobalHtmlAttrs<GlobalHTMLAttrs>(rest)

  const handleChange = (value) => {
    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }

  const styleClassname = style === 'default'
    ? cx('outline-none', value ? 'bg-gold-30' : 'bg-gray-50')
    : cx('outline outline-2 outline-gold-30', value ? 'bg-gold-70' : 'bg-pink')
  const rootClassName = cx('relative cursor-pointer', styleClassname, s[`size-${size}`], {
    [s.active]: value,
  })

  return (
    <SwitchWrapper
      className={rootClassName}
      checked={value}
      onChange={handleChange}
      {...globalHtmlAttrs}
    >
      <div className={s.handle} />
    </SwitchWrapper>
  )
}

const Switch: SwitchComponent = React.memo(Component)

Switch.Label = SwitchWrapper.Label
Switch.Group = SwitchWrapper.Group


export default Switch
