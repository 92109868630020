import React, { forwardRef } from 'react'
import { twcx } from 'helpers/twcx'

import { ButtonBase } from 'components/inputs'
import type { ButtonBaseProps } from 'components/inputs'

import s from './ButtonStyle.module.css'


export const sizes = [ 26, 38, 48, 56 ] as const

// ATTN this is constants that discussed with designers! don't change it w/o the purpose!
export const widthToClassNameMap = {
  120: 'min-w-[120rem]',
  164: 'min-w-[164rem]',
  200: 'min-w-[200rem]',
  240: 'min-w-[240rem]',
  280: 'min-w-[280rem]',
  320: 'min-w-[320rem]',
} as const

export const styles = [ 'primary', 'secondary', 'tertiary', 'gold-30', 'gold-30-secondary', 'gold-50-secondary', 'red', 'apple', 'apple-black-border' ] as const

type Size = typeof sizes[number]

type Width = keyof typeof widthToClassNameMap

type Style = typeof styles[number]

export type ButtonStyleProps = ButtonBaseProps & {
  // basic
  afterNode?: React.ReactNode

  // modifiers
  size: Size
  style?: Style

  noPadding?: boolean
  width?: Width
  fullWidth?: boolean
  fullWidthOnMobile?: boolean
}

const ButtonStyle = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonStyleProps>((props, ref) => {
  const {
    // basic
    children, className, afterNode,

    // modifiers
    size, style = 'primary',

    noPadding, width, fullWidth, fullWidthOnMobile, disabled, loading,

    ...rest
  } = props

  // Classes ----------------------- /
  const padding = noPadding ? null : (fullWidth ? 'px-8' : 'px-32')

  const nodeClassName = twcx(s.button, 'button rounded-none border-transparent', padding, s[style], s[`size-${size}`], widthToClassNameMap[width], {
    'w-full': fullWidth,
    'mobile:w-full mobile:px-8': fullWidthOnMobile,
    [s.styled]: style,
    [s.disabled]: disabled,
    [s.loading]: loading,
  }, className)

  const nodeProps = {
    className: nodeClassName,
    disabled,
    loading,
    ...rest,
  }

  return (
    <ButtonBase ref={ref} {...nodeProps}>
      {children}
      {afterNode}
    </ButtonBase>
  )
})


export default ButtonStyle
