import React, { useCallback } from 'react'
import { RadioGroup as RadioGroupBase } from '@headlessui/react'
import { useFieldState } from 'formular'
import type { Field } from 'formular'

import { Text } from 'components/dataDisplay'

import Option from './components/Option/Option'


export type RadioGroupProps = {
  className?: string
  children: React.ReactNode
  field: Field<any>
  label?: Intl.Message | string
  onChange?: (value: any) => void
  'aria-label'?: string
  'data-testid'?: string
}

type SubComponents = {
  Option?: typeof Option
  Label?: typeof RadioGroupBase.Label
  Description?: typeof RadioGroupBase.Description
}

type RadioGroupComponent = React.FunctionComponent<RadioGroupProps> & SubComponents

const Component: RadioGroupComponent = (props) => {
  const { children, className, field, label, 'aria-label': ariaLabel, 'data-testid': dataTestId, onChange } = props

  const state = useFieldState(field)

  const handleChange = useCallback((value) => {
    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ field, onChange ])

  return (
    <RadioGroupBase
      className={className}
      value={state.value}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      onChange={handleChange}
    >
      <>
        {
          Boolean(label) && (
            <RadioGroupBase.Label>
              <Text message={label} style="p4" color="gray-80" html />
            </RadioGroupBase.Label>
          )
        }
        {children}
      </>
    </RadioGroupBase>
  )
}

const RadioGroup: RadioGroupComponent = React.memo(Component)

RadioGroup.Option = Option
RadioGroup.Label = RadioGroupBase.Label
RadioGroup.Description = RadioGroupBase.Description


export default RadioGroup
