import React from 'react'
import { type GlobalHTMLAttrs } from 'helpers/getters'
import { Field, useFieldState } from 'formular'


export type HiddenInputProps = Omit<GlobalHTMLAttrs<'HTMLInputElement'>, 'type' | 'value'> & {
  field: Field<string | number>
}

const HiddenInput: React.FC<HiddenInputProps> = (props) => {
  const { field, ...rest } = props
  const { value, error } = useFieldState<string | number>(field)

  return (
    <input
      {...rest}
      type="hidden"
      value={value}
      aria-invalid={Boolean(error)}
    />
  )
}

export default HiddenInput

