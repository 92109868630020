import React, { forwardRef } from 'react'

import ButtonStyle, { type ButtonStyleProps } from '../ButtonStyle/ButtonStyle'
import ButtonContent, { type ButtonContentProps } from '../ButtonContent/ButtonContent'


export type SimpleButtonProps = ButtonStyleProps & ButtonContentProps

const SimpleButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, SimpleButtonProps>((props, ref) => {
  const { children, title, inlineIcon, style, html, loading, loadingMessage, ...rest } = props

  return (
    <ButtonStyle ref={ref} style={style} loading={loading} {...rest}>
      <ButtonContent
        title={title}
        inlineIcon={inlineIcon}
        loading={loading}
        loadingMessage={loadingMessage}
        html={html}
      >
        {children}
      </ButtonContent>
    </ButtonStyle>
  )
})


export default SimpleButton
