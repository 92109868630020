import React, { forwardRef, useCallback, useRef, useState } from 'react'
import { Field, useFieldState } from 'formular'
import { useCombinedRefs, useUniqueId } from 'hooks'
import { Message } from 'intl'
import { required } from 'helpers/validators'
import type { GlobalHTMLAttrs } from 'helpers/getters'
import { getGlobalHtmlAttrs } from 'helpers/getters'
import { cx, twcx } from 'helpers/twcx'

import { useIntl } from 'intl'
import { Icon } from 'components/dataDisplay'
import InputNote from '../InputNote/InputNote'
import InputError from '../InputError/InputError'


import masks from './util/masks'

import messages from './messages'

import s from './Input.module.css'


export const sizes = [ 48, 56 ] as const

export type InputSize = typeof sizes[number]

export type InputProps = GlobalHTMLAttrs<'HTMLInputElement'> & {
  className?: string
  inputClassName?: string
  inputErrorClassName?: string
  id?: string
  field: Field<string | number>
  mask?: keyof typeof masks
  pattern?: string
  size: InputSize
  label: Intl.Message | string
  labelStyle?: 'p2' | 'p4'
  note?: Intl.Message | string
  withCross?: boolean
  withBorder?: boolean
  withCheckmark?: boolean
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onChange?: (value: any) => void
  onChangeEvent?: React.ChangeEventHandler<HTMLInputElement>
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, forwardedRef) => {
  const {
    className, inputClassName, inputErrorClassName, id, field, size, label, note, mask, pattern,
    onFocus, onBlur, onChange, onChangeEvent, onKeyDown,
    withCross = false,
    withCheckmark = false,
    labelStyle = 'p2',
    'data-testid': dataTestId = 'input',
    withBorder = true,
    ...otherProps
  } = props

  const intl = useIntl()

  const inputRef = useRef<HTMLInputElement>(null)
  const combinedRef = useCombinedRefs([ inputRef, forwardedRef, field.props.ref ])

  const [ isFocused, setFocusedState ] = useState(false)

  const { value, error } = useFieldState<string | number>(field)

  const modifyValue = useCallback((value: string) => {
    const applyMask = masks[mask]

    if (typeof applyMask === 'function') {
      value = applyMask(value)
    }
    else if (pattern) {
      const r = new RegExp(`[^${pattern}]+`, pattern.includes('\p') ? 'gui' : 'gi')
      value = value.replace(r, '')
    }

    return value
  }, [ mask, pattern ])

  const handleFocus = useCallback((event) => {
    setFocusedState(true)

    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }, [ onFocus ])

  const handleBlur = useCallback(async (event) => {
    // @ts-ignore
    await field.validate()
    setFocusedState(false)

    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }, [ field, onBlur ])

  const handleChange = useCallback((event) => {
    let value = modifyValue(String(event.target.value))

    // Imagine we have input with mask: "XX/XX/XXX" for date. user can type incorrect
    // value in field: "11/A", in this case "A" will be removed from value and field.set
    // be called with value "11", previous value (before user type "A") was "11" - react
    // will not re-render the component, but native "uncontrolled" change in the input already
    // happened and user sees "11/A" in the input. To avoid this we need replace input's value manually.
    inputRef.current.value = value

    field.set(value)

    if (typeof onChangeEvent === 'function') {
      onChangeEvent(event)
    }

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ field, modifyValue, onChange, onChangeEvent ])

  const handleClear = () => {
    const value = ''
    inputRef.current.value = value
    field.set(value)
  }

  const isFilled = value !== ''
  const isErrored = Boolean(error)
  const isRequired = field.validators.includes(required)

  const rootClassName = twcx(s.root, s[`size-${size}`], {
    [s.focused]: isFocused,
    [s.filled]: isFilled,
    [s.errored]: isErrored,
  }, className)

  const labelClassName = cx(s.label, s[`label-${labelStyle}`])

  const uniqueId = useUniqueId('input-')
  const controlId = id || uniqueId
  const htmlAttrs = getGlobalHtmlAttrs<GlobalHTMLAttrs<'HTMLInputElement'>>(otherProps)

  return (
    <div className={rootClassName}>
      <div className="relative">
        <label className={labelClassName} htmlFor={controlId}>
          <Message value={label} />
        </label>
        <input
          {...htmlAttrs}
          ref={combinedRef}
          id={controlId}
          className={twcx(
            s.input,
            'w-full rounded bg-white text-black',
            withBorder ? 'border-solid-gray-30' : 'border-solid-white',
            inputClassName
          )}
          value={value}
          placeholder=""
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          data-testid={dataTestId}
          aria-invalid={isErrored}
          aria-required={isRequired}
        />
        {
          withCheckmark && field.state.isValid && !isFocused && isFilled && (
            <Icon
              className="absolute top-[65%] -translate-y-1/2 translate-x-full"
              style={{ left: `${(value.toString().length * 9)}rem` }}
              name="16/checkmark-small"
              color="green"
            />
          )
        }
        {
          withCross && isFilled && (
            <button
              className={cx('absolute cursor-pointer', s.cross)}
              type="button"
              onClick={handleClear}
              aria-label={intl.formatMessage(messages.clear)}
            >
              <Icon name="24/close" />
            </button>
          )
        }
      </div>
      {
        Boolean(note && !isErrored) && (
          <InputNote
            className="mt-4"
            message={note}
            data-testid={`${dataTestId}Note`}
          />
        )
      }
      {
        isErrored && (
          <InputError
            className={twcx('mt-4', inputErrorClassName)}
            message={error}
            data-testid={`${dataTestId}Error`}
          />
        )
      }
    </div>
  )
})

Input.displayName = 'Input'


export default Input
