import React, { useCallback } from 'react'
import cx from 'classnames'
import { Field, useFieldState } from 'formular'

import type { TextStyle } from 'components/dataDisplay'

import Chip, { type Color } from '../../dataDisplay/Chip/Chip'


export type Option<T> = {
  label: Intl.Message | string
  value: T
}

export type ChipSelectProps<T> = {
  className?: string
  itemClassName?: string
  color: Color
  labelStyle?: TextStyle
  field: Field<T>
  options: Option<T>[]
  onChange?: (value: T) => void
  'data-testid'?: string
}

const ChipSelect = <T, >(props: ChipSelectProps<T>) => {
  const { className, itemClassName, color, labelStyle, field, onChange, options, 'data-testid': dataTestId = 'chip' } = props

  const { value } = useFieldState<T>(field)

  const handleChange = useCallback((value) => () => {
    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ field, onChange ])

  return (
    <div className={cx(className, 'flex flex-wrap gap-8')} data-testid={dataTestId}>
      {
        options.map((option) => {
          const { label, value: optionValue } = option

          const active = value === optionValue
          const handleClick = handleChange(optionValue)

          return (
            <Chip
              key={optionValue.toString()}
              className={itemClassName}
              label={label}
              labelStyle={labelStyle}
              active={active}
              color={color}
              onClick={handleClick}
              data-testid={`${dataTestId}-option-${optionValue}`}
            />
          )
        })
      }
    </div>
  )
}

export default React.memo(ChipSelect)
