import React, { forwardRef } from 'react'

import type { IconName } from 'components/dataDisplay'

import ButtonStyle, { type ButtonStyleProps } from '../ButtonStyle/ButtonStyle'
import ButtonContent, { type ButtonContentProps } from '../ButtonContent/ButtonContent'
import ButtonIcon from './ButtonIcon/ButtonIcon'


export type ButtonWithIconProps = ButtonStyleProps & ButtonContentProps & {
  leftIcon?: IconName
  rightIcon?: IconName
}

const ButtonWithIcons = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonWithIconProps>((props, ref) => {
  const {
    children, title, size,
    loading, loadingMessage,
    fullWidth, fullWidthOnMobile,
    leftIcon, rightIcon,
    ...rest
  } = props

  return (
    <ButtonStyle
      ref={ref}
      size={size}
      loading={loading}
      fullWidth={fullWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      noPadding
      {...rest}
    >
      <div className="flex size-full items-center justify-between">
        <ButtonIcon name={leftIcon} />
        <ButtonContent
          title={title}
          loading={loading}
          loadingMessage={loadingMessage}
        >
          {children}
        </ButtonContent>
        <ButtonIcon name={rightIcon} />
      </div>
    </ButtonStyle>
  )
})


export default ButtonWithIcons
