import React from 'react'
import cx from 'classnames'
import { Message } from 'intl'

import { Icon } from 'components/dataDisplay'
import { ButtonBase } from 'components/inputs'
import type { ButtonBaseProps } from 'components/inputs'


export const styleMap = {
  'gold-50': {
    base: 'text-gold-50',
    disabled: 'text-gray-50',
    hover: 'desktop-hover:text-gold-30',
  },
  'gold-30': {
    base: 'text-gold-30',
    disabled: 'text-gray-30',
    hover: 'desktop-hover:text-pink',
  },
  'black': {
    base: 'text-black',
    disabled: 'text-gray-50',
    hover: 'desktop-hover:text-gray-80',
  },
  'white': {
    base: 'text-white',
    disabled: 'text-gray-30',
    hover: null,
  },
} as const


export type ActionButtonStyle = keyof typeof styleMap

export type ActionButtonProps = Omit<ButtonBaseProps, 'style'> & AtLeastOne<{
  children: React.ReactElement
  title?: Intl.Message | string
}> & {
  style?: ActionButtonStyle
  wordBreak?: boolean
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = (props) => {
  const {
    children, className, title, loading, disabled, style = 'gold-50', wordBreak = false,
    ...rest
  } = props

  const classes = styleMap[style]

  const titleClassName = cx('text-left uppercase', !wordBreak && 'whitespace-nowrap')
  const buttonClassName = cx(
    className,
    'inline-block select-none text-t5',
    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    classes.base,
    disabled ? classes.disabled : classes.hover
  )

  const buttonBaseProps = {
    className: buttonClassName,
    loading,
    disabled,
    ...rest,
  }

  return (
    <ButtonBase {...buttonBaseProps}>
      <span className="flex items-center justify-between">
        <span className={titleClassName}>
          {
            title ? (
              <Message value={title} />
            ) : (
              children
            )
          }
        </span>
        <Icon className="ml-14 flex-none" name="other/long-arrow-right" />
      </span>
    </ButtonBase>
  )
}


export default ActionButton
