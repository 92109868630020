import React from 'react'
import cx from 'classnames'
import { Message } from 'intl'


type InputNoteProps = {
  className?: string
  message: string | Intl.Message
  'data-testid'?: string
}

const InputNote: React.FunctionComponent<InputNoteProps> = (props) => {
  const { className, message, 'data-testid': dataTestId } = props

  return (
    <div className={cx(className, 'text-t3 text-gray-80')} data-testid={dataTestId}>
      <Message value={message} />
    </div>
  )
}


export default InputNote
