import React from 'react'
import cx from 'classnames'
import { Message } from 'intl'


type InputErrorProps = {
  className?: string
  message: string | Intl.Message
  'data-testid'?: string
}

const InputError: React.FunctionComponent<InputErrorProps> = (props) => {
  const { className, message, 'data-testid': dataTestId = 'error' } = props

  return (
    <div className={cx(className, 'text-t3 text-red')} data-testid={dataTestId}>
      <Message value={message} />
    </div>
  )
}


export default InputError
