import React from 'react'
import { Icon } from 'components/dataDisplay'
import type { IconName } from 'components/dataDisplay'


export type ButtonIconProps = {
  name: IconName
}

const ButtonIcon: React.FunctionComponent<ButtonIconProps> = ({ name }) => {
  return (
    <span className="relative h-full">
      <svg className="h-full w-auto" width={2} height={2} viewBox="0 0 2 2" />
      {
        Boolean(name) && (
          <span className="absolute left-0 top-0 flex size-full items-center justify-center">
            <Icon name={name} />
          </span>
        )
      }
    </span>
  )
}


export default ButtonIcon
