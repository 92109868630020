import React from 'react'
import cx from 'classnames'


export type SelectableCardBaseProps = {
  className?: string
  isActive?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  'aria-label'?: string
  'data-testid'?: string
}

const SelectableCardBase: React.CFC<SelectableCardBaseProps> = (props) => {
  const { children, className, isActive, disabled, onClick, 'aria-label': ariaLabel, 'data-testid': dataTestId } = props

  if (typeof onClick === 'function') {
    const rootClassName = cx(
      className,
      'w-full cursor-pointer rounded text-left',
      isActive ? 'border-solid-gold-50' : 'border-solid-gray-30 desktop-hover:border-gold-30'
    )

    return (
      <button
        className={rootClassName}
        type="button"
        onClick={onClick}
        disabled={disabled}
        data-testid={dataTestId}
        aria-selected={isActive}
        aria-label={ariaLabel}
      >
        {children}
      </button>
    )
  }

  return (
    <div className={cx(className, 'border-solid-gray-30 rounded')} data-testid={dataTestId}>
      {children}
    </div>
  )
}


export default SelectableCardBase
