import React from 'react'
import { Message } from 'intl'

import { Icon, type IconName } from 'components/dataDisplay'

import messages from '../messages'


export type ButtonContentProps = {
  children?: React.ReactNode
  title?: string | Intl.Message
  inlineIcon?: Extract<IconName, '24/plus' | '24/checkmark' | '32/arrow-right' | '36/video-white-arrow' | 'other/long-arrow-right' | '64/apple-pay'>
  html?: boolean
  loading?: boolean
  loadingMessage?: string | Intl.Message
}

const ButtonContent: React.FunctionComponent<ButtonContentProps> = (props) => {
  const { children, title, inlineIcon, html, loading, loadingMessage } = props

  if (loading) {
    return (
      <Message value={loadingMessage || messages.loading} />
    )
  }

  let inlineIconClassName = 'ml-4'

  if (inlineIcon === '32/arrow-right') {
    inlineIconClassName = null
  }
  else if (inlineIcon === '36/video-white-arrow') {
    inlineIconClassName = 'ml-12'
  }

  if (title) {
    return (
      <>
        <Message value={title} html={html} />
        {
          Boolean(inlineIcon) && (
            <Icon
              className={inlineIconClassName}
              name={inlineIcon}
            />
          )
        }
      </>
    )
  }

  return (
    <>
      {children}
    </>
  )
}


export default ButtonContent
