import React, { useCallback } from 'react'
import { Field, useFieldState } from 'formular'
import cx from 'classnames'

import { Text, CheckboxIcon, type TextStyle, type CheckboxIconProps } from 'components/dataDisplay'

import InputError from '../InputError/InputError'


export type CheckboxProps = {
  className?: string
  field: Field<any>
  style?: CheckboxIconProps['style']
  label?: Intl.Message | string
  labelStyle?: TextStyle
  errorClassName?: string
  errorText?: Intl.Message | string
  customError?: React.ReactNode
  value?: any
  disabled?: boolean
  fullWidth?: boolean
  onClick?: () => void
  'aria-label'?: string
  'data-testid'?: string
}

const Checkbox: React.CFC<CheckboxProps> = (props) => {
  const {
    children, className, field, label, labelStyle = 'p2', style, value,
    disabled, fullWidth, errorClassName = 'mt-4', errorText, customError, onClick,
    'aria-label': ariaLabel, 'data-testid': dataTestId,
  } = props

  const state = useFieldState(field)

  const isCheckboxGroup = Array.isArray(state.value)
  const isChecked = isCheckboxGroup ? state.value.includes(value) : state.value

  const rootClassName = cx('inline-flex cursor-pointer select-none items-start text-left align-top', className, {
    'cursor-not-allowed': disabled,
    'w-full': fullWidth,
  })

  const handleClick = useCallback(() => {
    // checkbox group
    if (Array.isArray(state.value)) {
      if (state.value.includes(value)) {
        field.set(state.value.filter((v) => v !== value))
      }
      else {
        field.set([ ...state.value, value ])
      }
    }
    // single checkbox
    else {
      field.set(!state.value)
    }

    if (typeof onClick === 'function') {
      onClick()
    }
  }, [ field, state.value, value, onClick ])

  const error = customError ? (
    customError
  ) : (
    <InputError
      className={errorClassName}
      message={errorText || state.error}
      data-testid={`${dataTestId}Error`}
    />
  )

  return (
    <>
      <button
        className={rootClassName}
        type="button"
        role="checkbox"
        disabled={disabled}
        aria-label={ariaLabel}
        aria-checked={value}
        data-testid={dataTestId}
        onClick={handleClick}
      >
        <CheckboxIcon
          style={style}
          active={isChecked}
          disabled={disabled}
          error={Boolean(state.error)}
        />
        {
          Boolean(label) && (
            <Text
              className="ml-16"
              message={label}
              style={labelStyle}
              color={disabled ? 'gray-80' : 'black'}
              html
            />
          )
        }
        {children}
      </button>
      {
        Boolean(state.error) && error
      }
    </>
  )
}


export default Checkbox
