import React, { forwardRef, useCallback } from 'react'
import { getGlobalHtmlAttrs, type GlobalHTMLAttrs } from 'helpers/getters'

import { Href } from 'components/navigation'


export type ButtonBaseProps = GlobalHTMLAttrs & {
  // basic
  children?: React.ReactNode
  className?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
  onMouseOver?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>

  // links
  to?: string
  href?: string
  toTab?: string

  disabled?: boolean
  loading?: boolean
  tag?: string
  type?: string
  id?: string

  'data-testid'?: string
  'data-cnstrc-btn'?: string
}

const ButtonBase = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonBaseProps>((props, ref) => {
  const {
    // basic
    children, id, className,

    // modifiers
    disabled, loading,

    // links
    to, toTab, href, onClick, onMouseOver,

    // misc
    tag = 'button', type = 'button',
    ...rest
  } = props

  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (disabled || loading) {
      event.preventDefault()
      return
    }

    if (typeof onClick === 'function') {
      onClick(event)
    }
  }, [ onClick, disabled, loading ])

  let node: string | React.ElementType = tag

  const htmlAttrs = getGlobalHtmlAttrs(rest)

  let nodeProps: any = {
    ref,
    className,
    id,
    onClick: handleClick,
    onMouseOver,
    'aria-disabled': disabled || loading, // we disable button in loading state
    'aria-busy': loading,
    ...htmlAttrs,
  }

  if (to || toTab || href) {
    node = Href

    nodeProps = {
      ...nodeProps,
      disabled,
      to,
      href,
      toTab,
    }
  }
  else if (tag === 'button' || tag === 'input') {
    nodeProps.disabled = disabled
    nodeProps.type = type
  }
  else {
    nodeProps.role = 'button'
  }

  return React.createElement(
    node,
    nodeProps,
    children
  )
})

ButtonBase.displayName = 'ButtonBase'


export default ButtonBase
