export const createMask = (formula) => {
  const patternArray = formula.split('')

  return (value) => {
    if (!value) {
      return ''
    }

    let result = '', index = 0

    for (let i = 0; i < patternArray.length; i++) {
      if (index >= value.length) {
        break
      }

      const symbol = patternArray[i]

      if (symbol === 'X' || symbol === value[index]) {
        result += value[index++]
      }
      else {
        result += symbol
      }
    }

    return result
  }
}

type CreateMaskWithModifiers = (params: {
  mask: string
  preModify?: (value: string) => string
  postModify?: (value: string) => string
}) => (value: string) => string

type Mask = ReturnType<CreateMaskWithModifiers>

const createMaskWithModifiers: CreateMaskWithModifiers = ({ mask, preModify, postModify }) => {
  const applyMask = mask ? createMask(mask) : null

  return (value) => {
    let newValue = value

    if (typeof preModify === 'function') {
      newValue = preModify(newValue)
    }

    if (typeof applyMask === 'function') {
      newValue = applyMask(newValue)
    }

    if (typeof postModify === 'function') {
      newValue = postModify(newValue)
    }

    return newValue
  }
}

// Mask result - mm/dd/yyyy
const initDateMask = (): Mask => {
  const preModify = (value) => value.replace(/\D/g, '')

  const postModify = (value) => {
    let [ month, day, year ] = value.split('/')

    if (month && month > 12) {
      month = 12
    }

    if (day && day > 31) {
      day = 31
    }

    return [ month, day, year ].filter(Boolean).join('/')
  }

  return createMaskWithModifiers({
    mask: 'XX/XX/XXXX',
    preModify,
    postModify,
  })
}

const initPhoneMask = (): Mask => {
  const preModify = (value) => value.replace(/\D/g, '')

  return createMaskWithModifiers({
    mask: '+1 XXX XXX XX XX',
    preModify,
  })
}


export default {
  date: initDateMask(),
  phone: initPhoneMask(),
}
