import React from 'react'
import cx from 'classnames'
import { RadioGroup as RadioGroupBase } from '@headlessui/react'

import s from './Option.module.css'


export type RadioOptionChildProps = {
  checked?: boolean
  active?: boolean
  disabled?: boolean
}

type OptionProps = {
  children: React.ReactElement<RadioOptionChildProps>
  className?: string
  value: any
  disabled?: boolean
  'aria-label'?: string
  'data-testid'?: string
}

const Option: React.FunctionComponent<OptionProps> = (props) => {
  const { className, value, children, disabled, 'aria-label': ariaLabel, 'data-testid': dataTestId } = props

  const rootClassName = cx(
    className,
    s.root,
    'group', // required for hover states of RadioIcon
    {
      [s.disabled]: disabled,
    }
  )

  return (
    <RadioGroupBase.Option
      className={rootClassName}
      value={value}
      disabled={disabled}
      aria-label={ariaLabel}
      data-testid={dataTestId}
    >
      {
        (props) => React.cloneElement(React.Children.only(children), props)
      }
    </RadioGroupBase.Option>
  )
}


export default React.memo(Option)
