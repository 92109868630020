import { forwardRef, useMemo } from 'react'
import { Combobox } from '@headlessui/react'
import { Field, useFieldState } from 'formular'
import { Input, type InputProps } from 'components/inputs'
import { Icon } from 'components/dataDisplay'
import Option from './components/Option/Option'


type AutocompleteProps = {
  size?: 48 | 56
  field: Field<string>
  label: Intl.Message | string
  isFetching: boolean
  onSelect: (value: any) => void
  onChange: (value: any) => void
  children: React.ReactElement<typeof Option>[]
  inputTestId?: string
  containerTestId?: string
}

const InputWithAutocomplete: React.FunctionComponent<AutocompleteProps> & { Option?: typeof Option } = (props) => {
  const {
    size = 48,
    field,
    label,
    onSelect,
    onChange,
    isFetching,
    children,
    inputTestId,
    containerTestId,
  } = props

  const { value } = useFieldState(field)

  const options = useMemo(() => {
    if (!isFetching && children?.length === 0) {
      return null
    }

    if (isFetching) {
      return (
        <div className="flex h-full items-center justify-center">
          <Icon name="20/loader" />
        </div>
      )
    }

    return children
  }, [ isFetching, children ])

  return (
    <Combobox
      value={value}
      onChange={onSelect}
      // required to allow empty input without an option selected
      nullable
    >
      <div className="relative">
        <Combobox.Input
          as={InputWrapper}
          label={label}
          size={size}
          field={field}
          autoComplete="off"
          withCross
          onValueChange={onChange}
          data-testid={inputTestId}
        />
        {
          Boolean(options) && (
            <Combobox.Options
              className="absolute left-0 z-floating-header mt-12 max-h-[240rem] w-full overflow-y-auto rounded bg-white py-4 shadow"
              aria-busy={isFetching}
              data-testid={containerTestId}
            >
              {options}
            </Combobox.Options>
          )
        }
      </div>
    </Combobox>
  )
}

// this wrapper required because Combobox.Input provides its own onChange and expect event inside
type InputWrapperProps = Omit<InputProps, 'onChange' | 'onChangeEvent'> & {
  onChange: React.ChangeEventHandler<HTMLInputElement> // from Combobox.Input
  onValueChange: InputProps['onChange'] // our handler
}

const InputWrapper = forwardRef<HTMLInputElement, InputWrapperProps>((props, ref) => {
  const { onChange, onValueChange, ...rest } = props

  return (
    <Input
      {...rest}
      ref={ref}
      onChangeEvent={onChange}
      onChange={onValueChange}
    />
  )
})

InputWithAutocomplete.Option = Option

export default InputWithAutocomplete
