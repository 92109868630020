import React from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'

import { Icon } from 'components/dataDisplay'
import { ButtonBase, type ButtonBaseProps } from 'components/inputs'


export const sizeToClassNameMap = {
  32: 'h-s32 w-s32',
  64: 'h-s64 w-s64',
  72: 'h-s72 w-s72',
}

type Size = keyof typeof sizeToClassNameMap

export type RoundButtonProps = Pick<ButtonBaseProps, 'to' | 'toTab' | 'href' | 'data-testid' | 'onClick' | 'loading' | 'disabled'> & {
  className?: string
  title: string | Intl.Message
  size: Size
}

const RoundButton: React.CFC<RoundButtonProps> = (props) => {
  const { children, className, title, href, to, toTab, size, loading, disabled, onClick, 'data-testid': dataTestId } = props

  const intl = useIntl()
  const ariaLabel = intl.formatMessage(title)

  const rootClassName = cx('rounded-full transition-all duration-200 ease-out', sizeToClassNameMap[size], {
    'cursor-not-allowed opacity-80 grayscale': disabled,
  }, className)

  return (
    <ButtonBase
      className={rootClassName}
      href={href}
      to={to}
      toTab={toTab}
      disabled={disabled}
      loading={loading}
      aria-label={ariaLabel}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {
        loading ? (
          <Icon name="32/loader" color="gray-30" />
        ) : (
          children
        )
      }
    </ButtonBase>
  )
}


export default RoundButton
