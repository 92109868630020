import React from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'

import { Icon } from 'components/dataDisplay'
import type { IconName, IconColor } from 'components/dataDisplay'

import messages from './messages'
import s from './CloseIconButton.module.css'


export type CloseIconButtonProps = {
  className?: string
  icon?: IconName
  color?: IconColor
  withRemoveLabel?: boolean
  disabled?: boolean
  loading?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
  'aria-label'?: string | Intl.Message
  'data-testid'?: string
}

const CloseIconButton: React.FunctionComponent<CloseIconButtonProps> = (props) => {
  const {
    className,
    withRemoveLabel,
    icon = '32/close',
    color,
    disabled,
    loading,
    'aria-label': ariaLabel,
    'data-testid': dataTestId,
    onClick,
  } = props

  const intl = useIntl()
  const testId = dataTestId || (withRemoveLabel ? 'removeButton' : 'closeButton')
  const label = intl.formatMessage(ariaLabel || (withRemoveLabel ? messages.remove : messages.close))

  const handleClick = (event) => {
    if (loading) {
      return
    }

    if (typeof onClick === 'function') {
      onClick(event)
    }
  }

  // TODO add Icon Size here? - added on 2/5/21 by pavelivanov
  return (
    <button
      className={cx(s.button, className)}
      type="button"
      aria-label={label}
      aria-busy={loading}
      data-testid={testId}
      disabled={disabled}
      onClick={handleClick}
    >
      <Icon name={icon} color={color} />
    </button>
  )
}


export default CloseIconButton
