import React, { useCallback } from 'react'
import type { Field } from 'formular'
import { useFieldState } from 'formular'

import { Rating } from 'components/dataDisplay'
import { InputError } from 'components/inputs'
import type { RatingProps } from 'components/dataDisplay'


type SelectableRatingProps = Partial<Pick<RatingProps, 'size' | 'withHint' | 'hintPosition'>> & {
  field: Field<number>
  className?: string
  withLabel?: boolean
  withError?: boolean
  onChange?: (value: number) => void
}

const SelectableRating: React.FunctionComponent<SelectableRatingProps> = (props) => {
  const { className, field, size = 20, withHint = true, withError, hintPosition = 'bottom', onChange } = props

  const { value, error } = useFieldState<number>(field)

  const handleChange = useCallback((value: number) => {
    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ field, onChange ])

  return (
    <div className={className}>
      <Rating
        value={value}
        size={size}
        withHint={withHint}
        hintPosition={hintPosition}
        onChange={handleChange}
      />
      {
        Boolean(withError && error) && (
          <InputError className="mt-4" message={error} />
        )
      }
    </div>
  )
}


export default SelectableRating
